.parent{
	display: flex;
    width: "100%";
    aspect-ratio: "10 / 3";
}

.primary{
    color:White;
    font-size: 75px;
    text-align: center;
    position: relative;
    Font-Family: 'Arial';
    flex: 1;
  
}
.secondary{
    color:White;
    text-align: center;
    font-size:30px;
    position: relative;
    flex: 1;
    top: -600px;
    left: 13px;
}
.vr{
    position: relative;
    flex: 1;
    top: -140px;
    right: 320px;
}
.twitter{
    position: relative;
    background-color:transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    flex: 1;
    display: block;
    top: -20px;
    z-index: 1;


    
}
.instagram{
    position: relative;
    background-color:transparent;
    border: 0;
    outline: none;
    cursor: pointer;
    flex: 1;
    display: block;
    z-index: 1;
}

    .discord{
        position: relative;
        background-color:transparent;
        border: 0;
        outline: none;
        cursor: pointer;
        flex: 1;
        display: block;
        z-index: 1;
        top: 27px;
    

}